import React, { useContext, forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import { HashLink } from "react-router-hash-link";
import MuiLink from "@material-ui/core/Link";
import Icon from "components/templatesComponents/Icon";
import LanguageContext from "components/LanguageContext";
import PageVersionContext from "components/PageVersionContext";
import CanSendTagContext from "components/CanSendTagContext";
import { sanitizeUrl } from "@braintree/sanitize-url";
import { useLocation } from "react-router-dom";
import EulerianService from "services/EulerianService";
import languages from "utils/languagesTypes";

export const componentClassName = "Da-Link";

const internalNavigationUrls = (process.env.internal_navigation_urls || "").split(",");

const useStyles = makeStyles(() => ({
  noLink: {
    "a&[href]": {
      backgroundImage: "none",
    },
  },
}));

const RouterLink = forwardRef((props, ref) => <HashLink innerRef={ref} {...props} />);

const Link = forwardRef((props, ref) => {
  const {
    url,
    page,
    external,
    title,
    iconPosition,
    size,
    icon,
    button,
    linkComponent,
    disabled,
    children,
    onClick,
    className,
    layoutLocation,
    isSocialNetwork,
    ...others
  } = props;

  const { icon: fontIcon, iconDSFR } = icon;

  const displayFontIcon = !external && fontIcon && !iconDSFR;

  const classes = useStyles();
  const theme = useTheme();

  const { language } = useContext(LanguageContext);
  const canSendTag = useContext(CanSendTagContext);
  const { currentPageVersion, setCurrentPageVersion } = useContext(PageVersionContext);
  const { pathname, search } = useLocation();

  const { breadcrumb = [] } = currentPageVersion || {};

  if (!url && !page && !linkComponent) {
    return (
      <a onClick={(e) => e.preventDefault()} className={classnames(className, componentClassName)} {...others}>
        {children}
      </a>
    );
  }

  const sendTag = () => {
    if (canSendTag) {
      let name = title || (page && page.title) || (page && page.tab && page.tab.title) || children;

      if (Array.isArray(name)) {
        name.forEach((element) => {
          if (typeof element === "string") {
            name = element;
          }
        });
      }

      if (typeof name === "string" && breadcrumb) {
        EulerianService.sendClick(
          button
            ? { button: [`button::click::${name.toLowerCase()}`] }
            : { link: [`link::click::${name.toLowerCase()}`] }
        );
      }
    }
  };

  const handleClickLink = (e) => {
    if (!disabled) {
      sendTag();
      if (typeof onClick === "function") {
        onClick(e);
      }
      if (page && !external) {
        setCurrentPageVersion(page);
      }
    }
  };

  const dynamicProps = {
    component: RouterLink,
    target: external ? "_blank" : "_self",
    rel: external ? "noopener noreferrer" : undefined,
  };

  const getProtocol = (oldUrl) =>
    oldUrl && (oldUrl.match(/^(http|https):\/\//g) || oldUrl.startsWith("/")) ? oldUrl : `https://`.concat(oldUrl);
  const path = page && page.fullPath !== undefined ? page.fullPath || "/" : sanitizeUrl(getProtocol(url));
  if (external || !path.startsWith("/")) {
    dynamicProps.href =
      internalNavigationUrls.some((str) => url?.indexOf(str) !== -1) && !url?.includes("lang=")
        ? `${path}?lang=${languages[language]?.lang}`
        : path;
    dynamicProps.component = "a";
  } else {
    const [nextPathname, ...params] = path.split(/(\?|#)/i);
    let nextSearch = "";
    let nextHash = "";
    if (params && params.length > 1) {
      if (params.indexOf("?") !== -1) {
        nextSearch = `?${params[params.indexOf("?") + 1]}`;
      }
      if (params.indexOf("#") !== -1) {
        nextHash = `#${params[params.indexOf("#") + 1]}`;
      }
    }
    dynamicProps.to = {
      pathname: nextPathname,
      search: nextSearch,
      hash: nextHash,
      state: { prevPath: pathname + search },
    };
  }

  if (disabled) {
    dynamicProps.href = null;
  }
  const titleLink = title || (page ? page.title : null);

  // W3C: remove attribute "to" in span component
  if (typeof document !== "undefined") {
    const seeMoreLink = document.getElementById("spanLink");
    if (seeMoreLink) {
      seeMoreLink.removeAttribute("to");
    }
  }

  return (
    <MuiLink
      ref={ref}
      underline="none"
      onClick={handleClickLink}
      color={linkComponent && !disabled ? "initial" : "textPrimary"}
      title={titleLink}
      {...dynamicProps}
      className={classnames(
        `${button ? "" : "fr-link "}fr-link--${size}${iconPosition ? ` fr-link--icon-${iconPosition}` : ""}${
          !external && iconDSFR ? ` fr-icon-${iconDSFR}` : ""
        }${linkComponent ? "" : " fr-link-after"}`,
        linkComponent ? undefined : classes.noLink,
        className,
        componentClassName
      )}
      style={{ cursor: disabled ? "inherit" : "pointer" }}
      {...others}
    >
      {displayFontIcon && iconPosition === "left" && <Icon icon={fontIcon} style={{ marginRight: theme.spacing(1) }} />}
      {children}
      {displayFontIcon && iconPosition === "right" && <Icon icon={fontIcon} style={{ marginLeft: theme.spacing(1) }} />}
    </MuiLink>
  );
});

Link.propTypes = {
  url: PropTypes.string,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  title: PropTypes.string,
  onClick: PropTypes.func,
  external: PropTypes.bool,
  iconPosition: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.shape(),
  button: PropTypes.bool,
  linkComponent: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  layoutLocation: PropTypes.string,
  isSocialNetwork: PropTypes.bool,
};

Link.defaultProps = {
  url: null,
  page: null,
  title: null,
  onClick: () => null,
  external: false,
  iconPosition: "",
  size: "md",
  icon: {},
  button: false,
  linkComponent: false,
  disabled: false,
  children: null,
  className: "",
  layoutLocation: null,
  isSocialNetwork: false,
};

export default Link;
