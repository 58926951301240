import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import MuiLink from "@material-ui/core/Link";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import { eventKey } from "utils/commonUtils";
import LanguageContext from "components/LanguageContext";

import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(0.5),
    verticalAlign: "middle",
    fontSize: "1.2em",
  },
}));

const ExpansionText = (props) => {
  const { reducedLabel, expandedLabel, text, defaultExpanded, ...others } = props;

  const { language } = useContext(LanguageContext);

  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const handleClickExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const handleKeyDown = (event) => {
    if (event.key === eventKey) {
      handleClickExpand();
    }
  };

  const classes = useStyles();

  const Icon = isExpanded ? ExpandLess : ExpandMore;

  return (
    <div {...others}>
      {isExpanded ? text : ""}
      <MuiLink component="span" underline="none" onClick={handleClickExpand} onKeyDown={handleKeyDown} tabIndex={0}>
        {isExpanded ? expandedLabel : reducedLabel}
        <Icon
          className={classes.icon}
          title={isExpanded ? expandedLabel : reducedLabel}
          isHidden={false}
          aria-label={isExpanded ? t[language].common.close : t[language].common.open}
        />
      </MuiLink>
    </div>
  );
};

ExpansionText.propTypes = {
  reducedLabel: PropTypes.string.isRequired,
  expandedLabel: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool.isRequired,
};

export default ExpansionText;
