import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/styles";
import Autosuggest from "components/templatesComponents/Autosuggest";
import ComedecService from "services/ComedecService";
import MessageContext from "components/MessageContext";
import getComedecCities from "utils/comedecUtils";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "white",
    border: `1px solid ${theme.palette.divider};`,
    borderRadius: "4px",
    padding: "0 16px",
    height: "53.625px",
    fontSize: "1em",
    "&.Mui-focused": {
      borderColor: theme.palette.secondary.main,
    },
    "& input": {
      height: "1.4rem",
    },
    "& button": {
      marginRight: "-12px",
      color: theme.palette.componentColors[70],
    },
  },
}));
const useAutoSuggestStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    position: "relative",
    padding: 0,
  },
  suggestionsContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.componentColors[30]}`,
    borderBottom: "none",
    borderTop: "none",
    zIndex: 100,
    maxHeight: "300px",
    overflow: "auto",
  },
  suggestionsList: {
    listStyleType: "none",
    paddingLeft: "0 !important",
    margin: 0,
  },
  suggestion: {
    "&:hover, &[aria-selected=true]": {
      backgroundColor: theme.palette.componentColors[30],
    },
    "&:last-child": {
      borderBottom: `1px solid ${theme.palette.componentColors[30]}`,
    },
  },
}));

const SearchBar = (props) => {
  const { postalCode, codeDepartement, setCity } = props;

  const classes = useStyles();
  const autoSuggestClasses = useAutoSuggestStyles();

  const { language } = useContext(LanguageContext);
  const { displayError } = useContext(MessageContext);

  const [value, setValue] = useState("");

  useEffect(() => {
    if (postalCode && postalCode !== codeDepartement) {
      setValue("");
      setCity({});
    }
  }, [postalCode, codeDepartement, setCity]);

  const getSuggestions = async (val) => {
    try {
      const results = await ComedecService.getCities({ city: val });
      let { data: cities } = results;
      if (postalCode) {
        cities = (Array.isArray(cities) && cities.filter((d) => d.codeDepartement === postalCode)) || [];
      }
      const comedecCities = await getComedecCities({ val, postalCode });
      const citiesExistedName = cities.map((cne) => cne.nom);
      if (Array.isArray(comedecCities)) {
        comedecCities.map((cl) => {
          return citiesExistedName.includes(cl.nom) || (postalCode && cl.codeDepartement !== postalCode)
            ? null
            : cities.push(cl);
        });
      }
      cities.sort((a, b) => a.nom.localeCompare(b.nom));
      return cities || [];
    } catch {
      try {
        const cities = [];
        const comedecCities = await getComedecCities({ val, postalCode });
        if (Array.isArray(comedecCities)) {
          comedecCities.map((cl) => {
            return postalCode && cl.codeDepartement !== postalCode ? null : cities.push(cl);
          });
        }
        cities.sort((a, b) => a.nom.localeCompare(b.nom));
        return cities;
      } catch {
        displayError(t[language].common.search.error_api_message);
        return [];
      }
    }
  };

  const getSuggestionValue = (suggestion) => suggestion.nom;

  const getCodeValue = (suggestion) => {
    return suggestion.codesPostaux ? suggestion.codesPostaux[0] : suggestion.code;
  };

  const renderSuggestion = (suggestion) => (
    <div key={suggestion.code} value={suggestion} onClick={() => setCity(suggestion)} style={{ cursor: "pointer" }}>
      {suggestion.nom}&nbsp;({getCodeValue(suggestion)})
    </div>
  );

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    setValue(suggestionValue);
    setCity(suggestion);
  };

  const renderInputComponent = (inputProps) => (
    <Input
      inputProps={{
        "aria-label": t[language].comedec.aria_label_text,
      }}
      role="combobox"
      aria-expanded="false"
      className={classes.input}
      disableUnderline
      {...inputProps}
      fullWidth
    />
  );

  const inputProps = {
    placeholder: t[language].comedec.placeholder,
    value,
    onChange,
    inputProps: {
      style: {
        border: "none",
      },
    },
  };

  // Accessibilité: suppresion d'un role="listbox" non pertinent
  if (typeof document !== "undefined") {
    const suggestContainer = document.getElementById("react-autowhatever-1");
    if (suggestContainer) {
      suggestContainer.removeAttribute("role");
    }
  }

  return (
    <Toolbar disableGutters>
      <Autosuggest
        getSuggestions={getSuggestions}
        debounce={300}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
        classes={autoSuggestClasses}
        noResult={
          <div>
            <b>
              {t[language].common.search.no_result_message} : &laquo; {value} &raquo;
            </b>
          </div>
        }
      />
    </Toolbar>
  );
};

SearchBar.propTypes = {
  postalCode: PropTypes.string,
  codeDepartement: PropTypes.string,
  setCity: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  postalCode: undefined,
  codeDepartement: undefined,
};

export default SearchBar;
