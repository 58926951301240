import React, { useMemo, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import SectionService from "services/SectionService";
import useAxiosCache from "hooks/axiosCache";
// import Onboarding from "components/LayoutFront/Onboarding";
import Link from "components/templatesComponents/Link";
import BackToTop from "components/LayoutFront/BackToTop";
import ScrollIndicator from "components/LayoutFront/ScrollIndicator";
import UserTabbingContext from "components/UserTabbingContext";
import LanguageSelector from "components/LayoutFront/LanguageSelector";
import generateTemplatePropsFromContents from "utils/templatePropsUtils";
import SiteContext from "components/SiteContext";
import LanguageContext from "components/LanguageContext";
import PageVersionContext from "components/PageVersionContext";
import SearchBar from "components/LayoutFront/SearchBar";
import SkipTo from "components/LayoutFront/SkipTo";
import { getEnglishItems } from "utils/urlUtils";
import { stringToSnakeCaseString } from "utils/commonUtils";
import languages from "utils/languagesTypes";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  rootWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "scroll",
    zIndex: 1100,
    [theme.breakpoints.up("lg")]: {
      height: "100%",
    },
    "@media print": {
      display: "block",
      overflow: "visible",
      overflowY: "visible",
    },
  },

  imageTitle: {
    [theme.breakpoints.down("sm")]: {
      width: "9rem",
    },
  },

  wrapper: {
    maxWidth: (props) => props.pageWidth,
    margin: "0 auto",
  },

  visibleMobile: {
    [theme.breakpoints.up("lg")]: {
      display: "none !important",
    },
  },

  topNav: {
    zIndex: 1100,
    [theme.breakpoints.up("lg")]: {
      background: theme.palette.componentColors[30],
      padding: 0,
    },
  },

  childrenWrapper: {
    flex: "1 0 auto",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "@media print": {
      paddingTop: 0,
    },
  },

  children: {
    flex: "1 0 auto",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },

  subMenuItems: {
    [theme.breakpoints.up("lg")]: {
      zIndex: 1,
      position: "absolute",
      top: 70,
      right: 0,
      left: 0,
      marginLeft: 0,
      backgroundColor: "white",
      boxShadow: theme.shadows[1],
      "& a": {
        color: theme.palette.componentColors[70],
      },
    },
  },
}));

const ScrollToTop = (props) => {
  const { scrollableClasses } = props;
  const { pathname, hash } = useLocation();

  let classes = scrollableClasses;
  if (!Array.isArray(scrollableClasses)) {
    classes = [scrollableClasses];
  }

  useEffect(() => {
    if (!hash || (hash && hash === "")) {
      classes.forEach((classe) => {
        const scrollableClass = document.getElementsByClassName(classe)[0];
        if (scrollableClass) {
          scrollableClass.scrollTop = 0;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
};

ScrollToTop.propTypes = {
  scrollableClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Navigation = (props) => {
  const { children, pageWidth, allPnuPages, associatedPnuPages, homePage } = props;

  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
  const [displayMobileNav, setDisplayMobileNav] = useState(true);
  const [displaySearchModal, setDisplaySearchModal] = useState(false);
  const [userTabbing, setUserTabbing] = useState(false);
  // const [topNavHeight, setTopNavHeight] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const site = useContext(SiteContext);
  const { language } = useContext(LanguageContext);
  const { name } = site || {};
  const { path: homePagePath, fullPath: homePageFullPath } = homePage;

  const connectUrl = `${process.env.ants_auth_url}?lang=${languages[language]?.lang}`;

  const classes = useStyles({
    displayMobileMenu,
    displayMobileNav,
    pageWidth,
    displaySearchModal,
    name,
  });
  // const topNavRef = useCallback(
  //   (node) => {
  //     if (node !== null && isMobile) {
  //       setTopNavHeight(node.getBoundingClientRect().height);
  //     }
  //   },
  //   [isMobile]
  // );

  const displayLanguageSelector = useMemo(() => !!allPnuPages.length, [allPnuPages]);

  useEffect(() => {
    const toggleMobileNav = (e) => {
      if (["INPUT", "TEXTAREA"].includes(e.target.tagName.toUpperCase())) {
        setDisplayMobileNav(false);
      } else {
        setDisplayMobileNav(true);
      }
    };

    document.addEventListener("click", toggleMobileNav);

    return () => {
      document.removeEventListener("click", toggleMobileNav);
    };
  }, []);

  const [{ data: header }] = useAxiosCache(SectionService.getConfig("getHeader", site.id));

  const headerContents = (header && generateTemplatePropsFromContents(header.contents)) || {};
  let { profiles = [] } = headerContents;
  if (!Array.isArray(profiles)) {
    profiles = [profiles];
  }

  const isCurrent = (profile) => site.host === (profile && profile.link && profile.link.url);

  const profilesDisplayed =
    profiles &&
    profiles.map((profile) => {
      const { id, title: siteTitle = "", link = {} } = profile;
      return (
        <li key={id}>
          <a
            href={link.url}
            key={id}
            aria-label={
              isCurrent(profile)
                ? siteTitle.concat(t[language].components.current_profile_label)
                : t[language].components.other_profile_label.concat(siteTitle)
            }
            className={`fr-btn ${isMobile && "fr-fi-arrow-left-line"}`}
          >
            {siteTitle}
          </a>
        </li>
      );
    });

  const [{ data: menu }] = useAxiosCache(SectionService.getConfig("getMenu", site.id));

  const menuContents = (menu && generateTemplatePropsFromContents(menu.contents)) || {};

  const menuItemsWithPNU = React.useMemo(() => {
    if (language.toUpperCase() === "EN") {
      return getEnglishItems({ items: menuContents.menus, allPnuPages }) || [];
    }
    return menuContents.menus || [];
  }, [language, menuContents, allPnuPages]);

  const [expandedMenuItem, setExpandedMenuItem] = useState({});

  const isOpen = (menuItem) => menuItem.title === expandedMenuItem.title;

  const { currentPageVersion } = useContext(PageVersionContext);

  const handleSubMenuClick = (menuItem) => {
    setExpandedMenuItem(isOpen(menuItem) ? {} : menuItem);
  };

  const closeAllSubMenus = () => {
    setExpandedMenuItem({});
  };

  const closeSubMenu = (item) => () => {
    if (isOpen(item)) {
      setExpandedMenuItem({});
    }
  };

  const handleMenuClick = () => {
    setDisplayMobileMenu(false);
    closeAllSubMenus();
  };

  // Ouvre le dropdown menu avec Espace ou Entrer
  const handleBtnKeyDown = (event, menuItem) => {
    if (event.target !== event.currentTarget) return;
    if (event.keyCode === 32 || event.keyCode === 13) {
      event.preventDefault();
      setExpandedMenuItem(isOpen(menuItem) ? {} : menuItem);
    }
  };

  // définis si l'utilisateur utilise le clavier pour naviguer dans le site
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("keydown", (event) => {
        if (event.keyCode === 9) {
          setUserTabbing(true);
        }
      });
      window.addEventListener("mousedown", () => {
        setUserTabbing(false);
      });
    }
  }, []);

  const isActive = (menuItem) => {
    if (Array.isArray(menuItem)) {
      return menuItem.some((item) => isActive(item));
    }
    const { link } = menuItem || {};
    const { page } = link || {};
    const { path } = page || {};
    if (currentPageVersion && path) {
      if (path === homePagePath) {
        return currentPageVersion.path === path;
      }
      if (currentPageVersion.fullPath) {
        if (language.toUpperCase() === "EN") {
          return currentPageVersion.fullPath.replace(homePagePath, "").startsWith(path);
        }
        return currentPageVersion.fullPath.startsWith(path);
      }
    }
    return false;
  };

  const getMenuLinkTitle = (menuItem) => {
    const { link, title: menuItemTitle, subMenus } = menuItem || {};
    if (!subMenus) {
      const { url } = link || {};
      return url ? t[language].menu[stringToSnakeCaseString(menuItemTitle)] : menuItemTitle;
    }
    return t[language].menu[stringToSnakeCaseString(menuItemTitle.trim())] || menuItemTitle;
  };

  const renderMenuGroup = (menuItem) => {
    const { id } = menuItem;
    let { subMenus } = menuItem;

    if (!subMenus || (Array.isArray(subMenus) && !subMenus.length)) {
      return null;
    }

    if (!Array.isArray(subMenus)) {
      subMenus = [subMenus];
    }

    return (
      <ClickAwayListener key={id} onClickAway={closeSubMenu(menuItem)}>
        <li
          className={classnames("fr-nav__item", isActive(subMenus) && "fr-nav__item--active")}
          style={{ position: "relative" }}
        >
          <button
            className="fr-nav__btn fr-nav__link"
            type="button"
            onClick={() => handleSubMenuClick(menuItem)}
            role="button"
            tabIndex="0"
            aria-expanded={isOpen(menuItem)}
            onKeyDown={(e) => {
              handleBtnKeyDown(e, menuItem);
            }}
            style={{ boxShadow: !isActive(subMenus) && "none" }}
          >
            {getMenuLinkTitle(menuItem)}
          </button>
          {isOpen(menuItem) ? (
            <ul className={classnames(classes.subMenuItems, "fr-menu__list")}>
              {subMenus.map((subMenuItem) => (
                <li className="fr-menu__item" key={subMenuItem.id}>
                  <Link
                    {...subMenuItem.link}
                    layoutLocation="header"
                    style={{ display: "block" }}
                    onClick={handleMenuClick}
                    className="fr-nav__link"
                  >
                    {getMenuLinkTitle(subMenuItem)}
                  </Link>
                </li>
              ))}
            </ul>
          ) : null}
        </li>
      </ClickAwayListener>
    );
  };

  return (
    <div className={classes.rootWrapper}>
      <UserTabbingContext.Provider value={userTabbing}>
        <div className={classes.root}>
          <SkipTo link="#main">Accéder directement au contenu</SkipTo>
          <SkipTo link="#nav">Accéder directement au menu principal</SkipTo>
          <ScrollToTop scrollableClasses={[classes.root, classes.children]} />
          <BackToTop scrollableClass={classes.root} />
          <ScrollIndicator scrollableClass={classes.root} />
          {/* <Onboarding top={topNavHeight} mobile={isMobile} /> */}
          <div className={classes.topNav}>
            <header
              role="banner"
              //  ref={topNavRef}
              className={classnames(classes.wrapper, "fr-header")}
            >
              <div className="fr-header__body" style={{ position: "relative" }}>
                <div className="fr-container">
                  <div className="fr-header__body-row">
                    <div className="fr-header__brand fr-enlarge-link">
                      <div className="fr-header__brand-top">
                        <div className="fr-header__logo">
                          <Link
                            url={homePageFullPath || homePagePath}
                            layoutLocation="header"
                            aria-label={t[language].components.header_logo}
                          >
                            <p className="fr-logo">
                              République
                              <br />
                              Française
                            </p>
                          </Link>
                        </div>
                        <div className="fr-header__logo" style={{ padding: 0 }}>
                          <img src="/logo_France_Titres_clair.svg" alt="" className={classes.imageTitle} />
                        </div>
                        <div className={classnames("fr-header__navbar", classes.visibleMobile)}>
                          <button
                            type="button"
                            className="fr-btn--search fr-btn"
                            title={t[language].common.search.placeholder}
                            data-fr-opened="false"
                            onClick={() => setDisplaySearchModal(true)}
                            style={{ marginRight: "0.5rem" }}
                          >
                            {t[language].common.search.placeholder}
                          </button>
                          <button
                            type="button"
                            className="fr-btn--menu fr-btn"
                            title={t[language].components.main_menu}
                            data-fr-opened="false"
                            id="fr-btn-menu-mobile-4"
                            onClick={() => setDisplayMobileMenu(true)}
                          >
                            {t[language].components.main_menu}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="fr-header__tools">
                      {[true, "true"].includes(process.env.display_version) && (
                        <Card style={{ position: "absolute", top: 0, left: 0 }}>
                          <Box sx={{ p: 0.5, color: "#ce0500", borderRadius: 5, border: "1px solid #ce0500" }}>
                            {process.env.npm_package_version}
                          </Box>
                        </Card>
                      )}
                      {!isMobile && (
                        <div className="fr-header__tools-links">
                          <ul className="fr-btns-group">{profilesDisplayed}</ul>
                          {displayLanguageSelector && <LanguageSelector associatedPnuPages={associatedPnuPages} />}
                        </div>
                      )}
                      <div className={`fr-header__search fr-modal ${displaySearchModal ? "fr-modal--opened" : ""}`}>
                        <SearchBar onSearch={() => setDisplaySearchModal(false)} />
                        {!isMobile && (
                          <a
                            type="button"
                            className="fr-btn fr-fi-user-line fr-btn--icon-left"
                            href={connectUrl}
                            target="_blank"
                            rel="noreferrer"
                            title={t[language].components.log_in_title}
                            style={{ minWidth: "158px" }}
                          >
                            {t[language].components.log_in}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`fr-header__menu fr-menu fr-modal ${displayMobileMenu ? "fr-modal--opened" : ""}`}
                id="modal-870"
                aria-labelledby="fr-btn-menu-mobile-3"
              >
                <div className="fr-container" style={isMobile ? { overflow: "auto" } : {}}>
                  <button type="button" className="fr-link--close fr-link" onClick={() => setDisplayMobileMenu(false)}>
                    Fermer
                  </button>
                  <nav className="fr-nav" id="nav" role="navigation" aria-label={t[language].components.main_menu}>
                    <ul className="fr-nav__list">
                      {menuItemsWithPNU?.map((menuItem) =>
                        !menuItem.subMenus && menuItem.link ? (
                          <li
                            className={classnames("fr-nav__item", isActive(menuItem) && "fr-nav__item--active")}
                            key={menuItem.id}
                          >
                            <Link
                              {...menuItem.link}
                              className="fr-nav__link fr-nav__link__item"
                              layoutLocation="header"
                              onClick={handleMenuClick}
                            >
                              {getMenuLinkTitle(menuItem)}
                            </Link>
                          </li>
                        ) : (
                          renderMenuGroup(menuItem)
                        )
                      )}
                    </ul>
                  </nav>
                  {isMobile && (
                    <div className="fr-header__menu-links">
                      <ul className="fr-btns-group">
                        <li>
                          <a className="fr-btn fr-icon-user-line" href={connectUrl} target="_blank" rel="noreferrer">
                            {t[language].components.log_in}
                          </a>
                        </li>
                        {profilesDisplayed}
                      </ul>
                      {displayLanguageSelector && <LanguageSelector associatedPnuPages={associatedPnuPages} />}
                    </div>
                  )}
                </div>
              </div>
            </header>
          </div>

          <div className={classes.childrenWrapper}>
            <div className={classes.children}>{children}</div>
          </div>
        </div>
      </UserTabbingContext.Provider>
    </div>
  );
};

Navigation.propTypes = {
  children: PropTypes.node,
  pageWidth: PropTypes.string,
  allPnuPages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  associatedPnuPages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  homePage: PropTypes.shape(),
};

Navigation.defaultProps = {
  children: null,
  pageWidth: "100%",
  homePage: {},
};

export default Navigation;
