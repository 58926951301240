import React, { useContext } from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const DSFRModal = (props) => {
  const { children, open, onClose, size } = props;
  const { language } = useContext(LanguageContext);

  const gridClass = React.useMemo(() => {
    return Object.entries(size).reduce((a, b) => {
      return a.concat(` fr-col-${b[0]}-${b[1]}`);
    }, "fr-col-12");
  }, [size]);

  if (!open) return null;

  return (
    <dialog
      aria-labelledby="fr-modal-title-modal-1"
      role="dialog"
      id="fr-modal-1"
      className={`fr-modal ${open ? "fr-modal--opened" : ""}`}
    >
      <ClickAwayListener onClickAway={onClose}>
        <div className="fr-container fr-container--fluid fr-container-md">
          <div className="fr-grid-row fr-grid-row--center">
            <div className={gridClass}>
              <div className="fr-modal__body">
                <div className="fr-modal__header">
                  <button
                    type="button"
                    className="fr-link--close fr-link"
                    title={t[language].components.close_modal_icon_title}
                    aria-controls="fr-modal-1"
                    onClick={onClose}
                  >
                    {t[language].common.close}
                  </button>
                </div>
                {children}
              </div>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </dialog>
  );
};

DSFRModal.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
  }),
};

DSFRModal.defaultProps = {
  children: null,
  size: {
    xs: 12,
    sm: null,
    md: null,
    lg: null,
  },
};

export default DSFRModal;
