const COOKIE_CONSENT_NAME = "cookieConsent";
const COOKIE_CONSENT_EXPIRES_NAME = "cookieConsentExpires";
const COOKIE_CONSENT_ID = "cookieConsentId";

const EULERIAN_ID_COOKIENAME = "etuix";
const EULERIAN_CONSENT_COOKIENAME = "eulerian";

class CookiesConsentService {
  static getConsent = () => {
    if (typeof localStorage !== "undefined") {
      const expiresDate = new Date(parseInt(localStorage.getItem(COOKIE_CONSENT_EXPIRES_NAME), 10));
      const cookieConsentId = localStorage.getItem(COOKIE_CONSENT_ID);

      if (
        (process.env.cookie_consent_id && cookieConsentId !== process.env.cookie_consent_id) ||
        (expiresDate && Date.now() > expiresDate.getTime())
      ) {
        localStorage.removeItem(COOKIE_CONSENT_NAME);
        localStorage.removeItem(COOKIE_CONSENT_EXPIRES_NAME);

        localStorage.removeItem(COOKIE_CONSENT_ID);
        localStorage.removeItem(EULERIAN_ID_COOKIENAME);
        CookiesConsentService.removeCookie(EULERIAN_ID_COOKIENAME);
        CookiesConsentService.removeCookie(EULERIAN_CONSENT_COOKIENAME);
      }
    }
    return localStorage.getItem(COOKIE_CONSENT_NAME);
  };

  static acceptConsent = () => {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem(COOKIE_CONSENT_NAME, true);
      localStorage.setItem(COOKIE_CONSENT_EXPIRES_NAME, Date.now() + this.getConsentMaxeAge() * 1000);
      localStorage.setItem(COOKIE_CONSENT_ID, process.env.cookie_consent_id);
    }
  };

  static refuseConsent = () => {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem(COOKIE_CONSENT_NAME, false);
      localStorage.setItem(COOKIE_CONSENT_EXPIRES_NAME, Date.now() + this.getConsentMaxeAge() * 1000);
      localStorage.setItem(COOKIE_CONSENT_ID, process.env.cookie_consent_id);
    }
  };

  static getConsentMaxeAge = () => {
    return 13 * 30 * 24 * 60 * 60; // 13 monthes
  };

  static removeCookie = (cookieName) => {
    const expire = new Date(0).toUTCString();
    document.cookie = `${cookieName}=; expires=${expire};`;
  };
}

export default CookiesConsentService;
