import axios from "axios";

class ContactService {
  static getWebmail = ({ contentId, pageId }) => {
    return axios.get("/@webmail", { params: { contentId, pageId } }).then((response) => response.data);
  };

  static postMail = (sendForm) => {
    return axios
      .post("/mail", sendForm)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return {
          status: err.response && err.response.status,
          message: err.response && err.response.data && err.response.data.error,
        };
      });
  };
}

export default ContactService;
