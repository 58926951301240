import React, { useContext } from "react";
import PropTypes from "prop-types";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const AllNewsSearch = (props) => {
  const { value, setValue } = props;

  const { language } = useContext(LanguageContext);

  const onChange = (event) => {
    const { value: newValue } = event.target;
    setValue(newValue);
  };

  return (
    <div className="fr-search-bar" id="search-865" role="search" style={{ marginBottom: "21px" }}>
      <label className="fr-label" htmlFor="search-865-input">
        {t[language].common.search.label}
      </label>
      <input
        className="fr-input"
        type="search"
        id="search-865-input"
        name="search-865-input"
        placeholder={t[language].all_news.placeholder}
        value={value}
        onChange={onChange}
      />
      <button type="button" className="fr-btn" title={t[language].common.search.placeholder}>
        {t[language].common.search.placeholder}
      </button>
    </div>
  );
};

AllNewsSearch.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default AllNewsSearch;
