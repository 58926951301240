import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import classnames from "classnames";
import Button from "components/templatesComponents/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Wrapper from "components/LayoutFront/Wrapper";
import { ImageContainer } from "components/templatesComponents/Image";
import Block from "components/templatesComponents/Block";
import PageTitle from "components/templatesComponents/PageTitle";
import Hidden from "components/templatesComponents/Hidden";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import ComedecService from "services/ComedecService";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SearchBar from "components/templates/comedec/SearchBar";
import Modal from "components/templatesComponents/Modal";
import { accentNormalize } from "utils/urlUtils";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";
import { getTranslateIndex } from "utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  wrapperProps: {
    position: "relative",
    paddingBottom: theme.spacing(8),
    maxWidth: "1440px",
    margin: "0 auto",
  },
  imageContainer: {
    position: "absolute",
    top: 16,
    right: 0,
    width: "40%",
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  popover: {
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    width: 412,
    maxHeight: 320,
  },
  select: {
    width: "100%",
    "&.Mui-error": {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  button: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.75, 2.5),
    marginRight: theme.spacing(1),
  },
  requiredFields: {
    position: "absolute",
    bottom: 0,
    fontStyle: "italic",
  },
  messages: {
    marginTop: theme.spacing(2),
  },
  red: {
    "& > b": {
      color: "red",
    },
  },
  info: {
    textAlign: "center",
    background: theme.palette.secondary.main,
    borderRadius: "20px",
    padding: theme.spacing(1, 2),
    fontSize: "1rem",
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },
  help: {
    padding: theme.spacing(4),
    "& > p": {
      paddingBottom: theme.spacing(1),
    },
  },
  selectDepartmentAndInfo: {
    position: "relative",
  },
  helperTextDepartment: {
    position: "absolute",
  },
}));

const FRANCE = "1";
const ABROAD = "2";

const Comedec = (props) => {
  const { page } = props;

  const { title, shortDescription, image, contents } = page;
  const { dynamicPart } = contents;

  const classes = useStyles();
  const theme = useTheme();

  const { language } = useContext(LanguageContext);

  const [country, setCountry] = useState(FRANCE);
  const [department, setDepartment] = useState("");
  const [city, setCity] = useState({});
  const [departments, setDepartments] = useState([]);
  const [returnCode, setReturnCode] = useState("");
  const [messageCityName, setMessageCityName] = useState("");
  const [messageDepartmentName, setMessageDepartmentName] = useState("");
  const [displayHelpModal, setDisplayHelpModal] = useState(false);
  const [getDepartmentsError, setGetDepartmentsError] = useState(false);
  const [isLoadingDepartments, setIsLoadingDepartments] = useState(true);

  const { nom: departmentName, code: postalCode } = department || {};

  const { nom: cityName, codeDepartement } = city || {};

  const getDepartments = async () => {
    try {
      const newDepartments = await ComedecService.getDepartments();
      setDepartments(newDepartments);
      setIsLoadingDepartments(false);
    } catch (e) {
      setGetDepartmentsError(true);
      setIsLoadingDepartments(false);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    if (!getDepartmentsError && codeDepartement) {
      setDepartment(Array.isArray(departments) && departments.find((dep) => dep.code === codeDepartement));
    }
  }, [departments, codeDepartement, getDepartmentsError]);

  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  const checkComedec = () => {
    if (window && cityName) {
      ComedecService.getCityInfos({ nom: accentNormalize(cityName.toUpperCase()), codeDepartement }).then(
        (response) => {
          // eslint-disable-next-line global-require
          const XMLParser = require("react-xml-parser");
          const json = new XMLParser().parseFromString(response);
          const children = json.children[0].children[0]?.children;
          const code = Array.isArray(children) && children.find((c) => c.name === "code_retour");
          setReturnCode(code ? code.value : "");
          setMessageCityName(cityName);
          setMessageDepartmentName(departmentName);
        }
      );
    }
  };

  let messages;

  switch (returnCode) {
    case "1":
      messages = [
        {
          key: "1",
          value: (
            <>
              {t[language].comedec.common_message} <b>{messageCityName}</b>
              {messageDepartmentName ? ` (${messageDepartmentName})` : ""} {t[language].comedec.no_demat_message1}
            </>
          ),
        },
        {
          key: "2",
          value: t[language].comedec.no_demat_message2,
        },
      ];
      break;
    case "2":
    case "3":
      messages = [
        {
          key: "1",
          value: (
            <>
              {t[language].comedec.common_message} <b>{messageCityName}</b>
              {messageDepartmentName ? ` (${messageDepartmentName})` : ""} {t[language].comedec.demat_message1}
            </>
          ),
        },
        {
          key: "2",
          value: <b>{t[language].comedec.demat_message2}</b>,
        },
        {
          key: "3",
          value: t[language].comedec.demat_message3,
        },
      ];
      break;
    default:
      break;
  }

  return (
    <Wrapper wrapperProps={{ className: classes.wrapperProps }}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} lg={image ? 6 : 12}>
          <Block>
            <PageTitle>{title}</PageTitle>
          </Block>
          {shortDescription && <Block>{shortDescription}</Block>}
        </Grid>
      </Grid>
      {image && (
        <Hidden mdDown>
          <div className={classes.imageContainer}>
            <ImageContainer ratio={48}>{image}</ImageContainer>
          </div>
        </Hidden>
      )}
      {dynamicPart && (
        <Grid item xs={12} lg={image ? 6 : 12}>
          <Block>{dynamicPart}</Block>
        </Grid>
      )}
      <Block>
        <Grid container alignItems="center" spacing={4}>
          <Grid item className={classnames(classes.red, "fr-text--bold")}>
            {t[language].comedec.country_label} : <b>*</b>
          </Grid>
          <Grid item xs component={FormControl}>
            <RadioGroup name="country" value={country} onChange={(e) => setCountry(e.target.value)} row>
              <FormControlLabel
                value={FRANCE}
                control={<Radio color="secondary" />}
                label={t[language].comedec.france}
              />
              <FormControlLabel
                value={ABROAD}
                control={<Radio color="secondary" />}
                label={t[language].comedec.foreign}
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </Block>
      {country === FRANCE && (
        <Block>
          <Grid container direction="column" spacing={2}>
            <Grid item className="fr-text--bold">
              {t[language].comedec.search_label} :
            </Grid>
            <Grid item container alignItems="center" spacing={2}>
              <Grid item xs={4} lg={2}>
                {t[language].comedec.department}
              </Grid>
              <Grid item xs lg={5}>
                <Grid container alignItems="center" className={classes.selectDepartmentAndInfo}>
                  <Grid item xs>
                    <FormControl error={getDepartmentsError}>
                      <Select
                        onChange={(e) => setDepartment(e.target.value)}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        name="department"
                        value={department}
                        displayEmpty
                        className={classes.select}
                        disabled={isLoadingDepartments || getDepartmentsError}
                      >
                        <MenuItem value="">{t[language].comedec.all_departments}</MenuItem>
                        {Array.isArray(departments) &&
                          departments.map((d) => (
                            <MenuItem key={d.code} value={d}>
                              {d.code}&nbsp;-&nbsp;{d.nom}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    className={classes.info}
                    title={t[language].comedec.tooltip_label}
                    onClick={() => setDisplayHelpModal(true)}
                  >
                    ?
                  </Grid>
                </Grid>
                {getDepartmentsError && (
                  <FormHelperText error className={classes.helperTextDepartment}>
                    {t[language].comedec.api_error_message}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              spacing={2}
              style={{ paddingTop: theme.spacing(getDepartmentsError ? 4.5 : 1) }}
            >
              <Grid item xs={4} lg={2} className={classes.red}>
                {t[language].comedec.city} <b>*</b>
              </Grid>
              <Grid item xs lg={5}>
                <SearchBar postalCode={postalCode} codeDepartement={codeDepartement} setCity={setCity} />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12} lg={7} container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => checkComedec()}
                  disabled={!cityName || !codeDepartement}
                >
                  {t[language].comedec.button_label}
                </Button>
              </Grid>
            </Grid>
            {returnCode && (
              <Grid
                item
                xs={12}
                lg={7}
                container
                justifyContent={isMobile ? "center" : "flex-start"}
                spacing={2}
                className={classes.messages}
              >
                {Array.isArray(messages) &&
                  messages.map((message) => (
                    <Grid item xs={10} lg={12} key={message.key}>
                      {message.value}
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>
          <p className={classnames(classes.requiredFields, classes.red)} role="presentation">
            <b>*</b> : {t[language].common.required_fields[getTranslateIndex(2)]}
          </p>
        </Block>
      )}
      {country === ABROAD && (
        <Block>
          <p>{t[language].comedec.foreign_message}</p>
        </Block>
      )}
      <Modal
        aria-labelledby="admin-content"
        aria-describedby="content-administration"
        open={displayHelpModal}
        onClose={() => setDisplayHelpModal(false)}
        size="md"
        className={classes.help}
      >
        <p>{t[language].comedec.modal_message}</p>
        <ul>
          <li>{t[language].comedec.modal_li1};,</li>
          <li>{t[language].comedec.modal_li2}</li>
          <li>{t[language].comedec.modal_li3}</li>
        </ul>
      </Modal>
    </Wrapper>
  );
};

Comedec.propTypes = {
  page: PropTypes.shape(),
};

Comedec.defaultProps = {
  page: {},
};

export default Comedec;
