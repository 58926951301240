import React, { useContext } from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";

const CookiesTable = (props) => {
  const {
    consent,
    cookiesRows,
    setConsent,
    disabled,
    description,
    subtitle,
    handleValidateClick,
    handleRefuseClick,
    classes,
  } = props;

  const { language } = useContext(LanguageContext);

  const [openDetails, setOpenDetails] = React.useState(false);

  const handleChange = (e, oldConsent) => {
    if (oldConsent === "Accepter") {
      handleRefuseClick();
    } else {
      handleValidateClick();
    }
    setConsent(e.target.value);
  };

  return (
    <div className="fr-consent-service">
      <fieldset
        aria-labelledby={`${subtitle}-legend ${subtitle}-desc`}
        role="group"
        className="fr-fieldset fr-fieldset--inline"
      >
        <legend id={`${subtitle}-legend`} className="fr-consent-service__title">
          {subtitle}
        </legend>
        <div className="fr-consent-service__radios">
          <RadioGroup value={disabled ? "Accepter" : consent} onChange={(e) => handleChange(e, consent)} row>
            <div className="fr-radio-group">
              <FormControlLabel
                label={t[language].components.accept_radio}
                value="Accepter"
                control={<Radio color="primary" disabled={disabled} className={classes.radio} />}
                className="fr-label"
                style={{ marginRight: "48px" }}
              />
            </div>
            <div className="fr-radio-group">
              <FormControlLabel
                label={t[language].components.refuse_radio}
                value="Refuser"
                control={<Radio color="primary" disabled={disabled} className={classes.radio} />}
                className="fr-label"
                style={{ marginRight: 0 }}
              />
            </div>
          </RadioGroup>
        </div>
        <div id={`${subtitle}-desc`} className="fr-consent-service__desc">
          {description}
        </div>
        <div className="fr-consent-service__collapse">
          <button
            type="button"
            className="fr-consent-service__collapse-btn"
            aria-expanded={openDetails ? "true" : "false"}
            aria-describedby={`${subtitle}-legend`}
            onClick={() => setOpenDetails(!openDetails)}
          >
            {t[language].components.see_more_details}
          </button>
        </div>
        {openDetails && (
          <div className="fr-consent-services">
            {Array.isArray(cookiesRows) &&
              cookiesRows.map((cookiesRow) => (
                <div className="fr-consent-service" key={cookiesRow.finality}>
                  <fieldset className="fr-fieldset fr-fieldset--inline">
                    <legend id={`${subtitle}-service-1-legend`} className="fr-consent-service__title">
                      {cookiesRow.name}
                    </legend>
                    <p id={`${subtitle}-service-1-desc`} className="fr-consent-service__desc" role="presentation">
                      {cookiesRow.finality}
                    </p>
                  </fieldset>
                </div>
              ))}
          </div>
        )}
      </fieldset>
    </div>
  );
};

CookiesTable.propTypes = {
  cookiesRows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  consent: PropTypes.string,
  setConsent: PropTypes.func,
  disabled: PropTypes.bool,
  description: PropTypes.shape(),
  subtitle: PropTypes.string.isRequired,
  handleValidateClick: PropTypes.func.isRequired,
  handleRefuseClick: PropTypes.func.isRequired,
  classes: PropTypes.shape(),
};

CookiesTable.defaultProps = {
  consent: "",
  setConsent: null,
  disabled: false,
  description: null,
  classes: {},
};

export default CookiesTable;
