import React, { useContext, Fragment } from "react";
import Icon from "components/templatesComponents/Icon";
import SiteContext from "components/SiteContext";
import { makeStyles } from "@material-ui/styles";
import severitiesTypes from "utils/severitiesTypes";

const getSeverityData = (severityValue) => {
  const severity = Object.keys(severitiesTypes).find((s) => severitiesTypes[s].key === severityValue) || {};
  return severitiesTypes[severity];
};

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    padding: theme.spacing(2, 0),
    backgroundColor: props.severity && getSeverityData(props.severity).backgroundColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      padding: theme.spacing(0, 0.5),
    },
  }),
  icon: (props) => ({
    fontSize: "24px",
    color: props.severity && getSeverityData(props.severity).iconColor,
    "&::before": {
      "--icon-size": "1.75rem",
    },
  }),
}));

const WarningBanner = () => {
  const site = useContext(SiteContext);
  const { settings: siteSettings } = site;
  const { warningBanner } = siteSettings || {};
  const { enable = false, severity, message } = warningBanner || {};
  const classes = useStyles({ severity });

  if (!enable) {
    return null;
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Icon
          icon={getSeverityData(severity).icon}
          iconDSFR={getSeverityData(severity).iconDSFR}
          className={classes.icon}
        />
        <div>{message}</div>
      </div>
    </Fragment>
  );
};

export default WarningBanner;
