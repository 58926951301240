import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Icon from "components/templatesComponents/Icon";
import MuiExpandLessIcon from "@material-ui/icons/ExpandLess";
import MuiExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";
import categories from "components/templates/geolocalisation/geolocalisation.categories.enum.json";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    paddingTop: theme.spacing(2),
    display: "flex",
    "& a": {
      padding: theme.spacing(1, 2),
      border: `1px solid ${theme.palette.secondary.main}`,
      marginRight: theme.spacing(1),
      color: "inherit",
      textDecoration: "none",
      backgroundImage: "none",
    },
  },
  openingContainer: {
    cursor: "pointer",
    marginTop: theme.spacing(2),
    fontSize: "0.77rem",
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(1),
    },
  },
  distanceCity: {
    color: theme.palette.componentColors[60],
    marginTop: theme.spacing(1),
    fontSize: "0.77rem",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  button: {
    padding: 0,
    display: "flex",
  },
}));

const InfoGeoloc = (props) => {
  const classes = useStyles();
  const { geoPointItem = {}, cityPosition, source } = props;

  const { type = "" } = geoPointItem._source || {};

  const { language } = useContext(LanguageContext);

  const { category = "", others } = geoPointItem._source || {};

  const {
    name = "",
    address = "",
    address2 = "",
    postalCode = "",
    city = "",
    phone = "",
    email = "",
    webSite = "",
    numVoie = "",
    typeVoie = "",
    libelleVoie = "",
  } = others || {};

  const { basic, specific } = categories[type] || {};

  const [isToggleOn, setToggle] = useState(false);

  const week = (t[language].geolocalisation.week_days || "").split(",");

  const d = new Date();
  const today = d.getDay() - 1;

  const webSiteUrl = !webSite.startsWith("https://") && !webSite.startsWith("http://") ? `https://${webSite}` : webSite;

  const addressToDisplay =
    source && source === "siv" ? `${numVoie} ${typeVoie} ${libelleVoie}` : `${address} ${address2}`;

  const cityToDisplay = `${postalCode} ${city}`;

  const iconTitle =
    category?.toLowerCase() === specific ? t[language].geolocalisation[specific] : t[language].geolocalisation[basic];
  const iconColor = category?.toLowerCase() === specific ? "#F32121" : "#396FF1";

  const iconDSFR = `${source === "mairie" ? "bank" : "camera"}-${
    category?.toLowerCase() === specific ? "fill" : "line"
  }`;

  return (
    <div style={{ flex: 1 }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        style={{ position: "relative", marginBottom: 8 }}
      >
        <h3 className="fr-h6" style={{ wordBreak: "break-word", marginBottom: 0 }} role="presentation">
          {name}
        </h3>
        {type !== "siv" && (
          <>
            <div aria-label={iconTitle} style={{ position: "absolute", right: 12 }} />
            <Icon icon="circle" type="fas" iconDSFR={iconDSFR} style={{ color: iconColor }} title={iconTitle} />
          </>
        )}
      </Grid>
      <p>
        {addressToDisplay} {cityToDisplay}
      </p>
      {geoPointItem.sort && geoPointItem.sort.length > 0 && geoPointItem.sort[0] && (
        <p className={classes.distanceCity} role="presentation">
          {`${Number.parseFloat(geoPointItem.sort[0]).toFixed(1)} km de ${cityPosition}`}
        </p>
      )}
      {phone && (
        <a
          href={`tel:${phone}`}
          className="fr-link fr-icon-phone-line fr-link--icon-left"
          title={t[language].geolocalisation.phone}
        >
          {phone}
        </a>
      )}
      {geoPointItem.opening &&
        geoPointItem.opening.length > 0 &&
        geoPointItem.opening.reduce((hasValue, opening) => hasValue || !!opening, false) && (
          <Grid container onClick={() => setToggle(!isToggleOn)} className={classes.openingContainer}>
            <Grid item style={{ maxWidth: "87%" }} role="list">
              {Array.isArray(week) &&
                week.map((day, i) => (
                  <div key={day} role="listitem">
                    {i === today ? (
                      <div style={{ display: "flex" }}>
                        <p>
                          {day} :{" "}
                          {!geoPointItem.opening[i] ? (
                            <i>{t[language].geolocalisation.no_schedule}</i>
                          ) : (
                            geoPointItem.opening[i]
                          )}
                        </p>{" "}
                      </div>
                    ) : (
                      <p style={{ display: isToggleOn ? "" : "none" }} role="presentation">
                        {day} :{" "}
                        {!geoPointItem.opening[i] ? (
                          <i>{t[language].geolocalisation.no_schedule}</i>
                        ) : (
                          geoPointItem.opening[i]
                        )}
                      </p>
                    )}
                  </div>
                ))}
            </Grid>
            <Grid item style={{ marginLeft: 4 }}>
              <button type="button" className={classes.button}>
                {isToggleOn ? <MuiExpandLessIcon /> : <MuiExpandMoreIcon />}
              </button>
            </Grid>
          </Grid>
        )}
      {(email || webSite) && (
        <div className={classes.buttonContainer} role="list">
          {email && (
            <a
              href={`mailto:${email}`}
              title={t[language].geolocalisation.mail}
              role="listitem"
              style={{ cursor: "pointer" }}
            >
              <Icon icon="at" iconDSFR="mail-line" className={classes.icon} />
              {t[language].geolocalisation.mail}
            </a>
          )}
          {webSite && (
            <a
              href={webSiteUrl}
              target="_blank"
              rel="noopener noreferrer"
              title={t[language].geolocalisation.site}
              role="listitem"
            >
              <Icon icon="globe" iconDSFR="earth-line" className={classes.icon} />
              {t[language].geolocalisation.site}
            </a>
          )}
        </div>
      )}
    </div>
  );
};

InfoGeoloc.propTypes = {
  geoPointItem: PropTypes.shape().isRequired,
  cityPosition: PropTypes.string.isRequired,
  source: PropTypes.string,
};

InfoGeoloc.defaultProps = {
  source: null,
};

export default InfoGeoloc;
