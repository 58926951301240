import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import videosOrigins from "utils/videosOrigins";
import { getQueryParams } from "utils/urlUtils";

const validURL = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

const Video = (props) => {
  const { src, source, legend, title, transcription, openButtonLabel, closeButtonLabel, ...others } = props;

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  const [displayTranscription, setDisplayTranscription] = useState(false);

  const createSrcByOrigin = () => {
    if (!src) return src;

    let newSrc = src.split("?")[0];
    const queryParams = getQueryParams(src);

    switch (source) {
      case videosOrigins.YOUTUBE: {
        const { v: video, t: time } = queryParams;
        if (newSrc.includes("youtu.be")) {
          newSrc = newSrc.replace("youtu.be", "www.youtube.com/embed/");
        } else {
          newSrc = newSrc.replace("watch", "embed/");
          if (video) {
            newSrc = newSrc.concat("", video);
          }
        }
        if (time) {
          newSrc = newSrc.concat("", `?start=${time}`);
        }
        return newSrc;
      }
      case videosOrigins.DAILYMOTION:
        return newSrc.replace("video", "embed/video");
      case videosOrigins.VIMEO:
        return newSrc.replace("https://vimeo.com", "https://player.vimeo.com/video");
      default:
        break;
    }
    return src;
  };

  const iframeSrc = validURL(createSrcByOrigin()) ? createSrcByOrigin() : "about:blank";

  return (
    <div className="fr-content-media">
      <iframe
        height="400"
        className="fr-responsive-vid"
        src={iframeSrc}
        title={title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        {...others}
      />
      <div className="fr-content-media__caption">{legend}</div>
      {(openButtonLabel || typeof openButtonLabel === "object") && (
        <div className="fr-content-media__transcription">
          <Grid container direction={isMobile ? "column-reverse" : "row"} spacing={2}>
            <Grid item xs>
              {(displayTranscription || typeof transcription.props.children === "object") && transcription}
            </Grid>
            <Grid item>
              <button
                type="button"
                className="fr-btn"
                style={{ minWidth: "150px", cursor: typeof openButtonLabel === "string" ? "pointer" : "inherit" }}
                onClick={() =>
                  typeof openButtonLabel === "string" ? setDisplayTranscription(!displayTranscription) : null
                }
              >
                {displayTranscription ? closeButtonLabel || openButtonLabel : openButtonLabel}
              </button>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  legend: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  transcription: PropTypes.shape(),
  openButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  closeButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
};

Video.defaultProps = {
  legend: "",
  title: "",
  transcription: null,
  openButtonLabel: "",
  closeButtonLabel: "",
};

export default Video;
