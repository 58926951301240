import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import Captcha from "components/Captcha";
import { makeStyles } from "@material-ui/styles";
import Button from "components/templatesComponents/Button";
import Modal, { ModalActions } from "components/templatesComponents/Modal";
import PageVersionContext from "components/PageVersionContext";
import ContactService from "services/ContactService";
import LinearProgress from "@material-ui/core/LinearProgress";
import languages from "utils/languagesTypes";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
  relative: {
    position: "relative",
  },
  loader: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const Mailto = (props) => {
  const classes = useStyles();
  const { text, recipient, subject, body, contentId } = props;
  const { currentPageVersion } = useContext(PageVersionContext);
  const { id: pageId } = currentPageVersion;
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const captchaRef = useRef(null);

  const language =
    typeof localStorage !== "undefined" && localStorage.getItem("language") !== "null"
      ? localStorage.getItem("language")
      : Object.keys(languages)[0];

  const onModalClose = () => {
    setOpenModal(false);
    setLoader(false);
    setShowModal(false);
  };

  const checkCaptcha = async (emptyCheck = false) => {
    if (captchaRef && captchaRef.current) {
      const captchaResponse = await captchaRef.current.checkToken();
      if (captchaResponse === "SUCCESS") {
        const webmail = await ContactService.getWebmail({ contentId, pageId });
        if (webmail) {
          window.location.href = webmail;
          onModalClose();
        }
      } else if (emptyCheck) {
        setShowModal(true);
      } else {
        captchaRef.current.setCaptchaErrorMessage(t[language].components.captcha_error_message);
      }
    }
  };
  const sendCandidate = async () => {
    setLoader(true);
    if (process.env.liveidentity_key) {
      if (window) {
        setOpenModal(true);
        // need a timeout because react render isn't immediate :
        // captchaRef is still null while checkCaptcha trigger first...
        setTimeout(() => checkCaptcha(true), 2000);
      }
    } else {
      setShowModal(true);
      if (recipient) {
        window.location.href = `mailto:${recipient}?subject=${subject}&body=${body}`;
      } else {
        const webmail = await ContactService.getWebmail({ contentId, pageId });
        if (webmail) {
          window.location.href = webmail;
        }
      }
      setLoader(false);
    }
  };

  return (
    <div className={classes.button}>
      {process.env.liveidentity_URL && (
        <Modal open={openModal} onClose={onModalClose} style={{ display: showModal ? "block" : "none" }} size="md">
          <form
            action="/"
            method="POST"
            onSubmit={(e) => {
              e.preventDefault();
              checkCaptcha();
            }}
          >
            <Captcha ref={captchaRef} id="sample-captcha" />

            <ModalActions>
              <Button outlined onClick={onModalClose}>
                {t[language].components.cancel_button_label}
              </Button>
              <Button type="submit">{t[language].components.emailto_validate_button_label}</Button>
            </ModalActions>
          </form>
        </Modal>
      )}
      <Button onClick={() => sendCandidate()} disabled={loader} className={classes.relative}>
        {text}
        {loader && <LinearProgress className={classes.loader} />}
      </Button>
    </div>
  );
};

Mailto.propTypes = {
  text: PropTypes.string.isRequired,
  recipient: PropTypes.string,
  subject: PropTypes.string,
  body: PropTypes.string,
  contentId: PropTypes.string.isRequired,
};

Mailto.defaultProps = {
  recipient: "",
  subject: "",
  body: "",
};

export default Mailto;
